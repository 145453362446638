import {LatLng} from "leaflet";
import {Marker, Polygon, Popup, Tooltip} from "react-leaflet";
import {getCentroid} from "../../components/helper";
import {iconPin} from "../../components/iconPin";
import {iconPinRed} from "../../components/iconPinRed";
import React from "react";
import PopupContent from "./PopupContent";
import {v4 as uuidv4} from 'uuid';

export function GenerateMarkers(dataLocation, map, setPopupActive, setMarkers) {

    return (bounds) => {

        const resultFilteredReal = dataLocation?.filter(m =>
            bounds.contains(new LatLng(m.Latitude, m.Longitude))
        );

        const polygonStyle = {
            color: 'blue',
            fillColor: "transparent",
            weight: 2,
        };

        const highlightedPolygonStyle = {
            weight: 5,
            color: 'blue',
            dashArray: '',
            fillOpacity: 0.7,
            fillColor: 'white'
        };

        function highlightFeature(e) {
            let layer = e.target;
            layer.setStyle(highlightedPolygonStyle);
            layer.bringToFront();
        }

        function resetHighlight(e) {
            let layer = e.target;

            layer.setStyle(polygonStyle);

            layer.bringToFront();
        }

        const result = resultFilteredReal.map((item) => (
                <>
                    <Tooltip>
                        <p>lat: {item.Latitude}<br/>lng: {item.Longitude}</p>
                    </Tooltip>
                    <Polygon
                        key={`Poly:${item.ID}:${uuidv4()}`}
                        positions={[[item.C1a, item.C1o], [item.C2a, item.C2o], [item.C3a, item.C3o], [item.C4a, item.C4o]]}
                        pathOptions={polygonStyle}
                        eventHandlers={{
                            click: () => {
                            },
                            mouseover: (e) => {
                                highlightFeature(e);
                            },
                            mouseout: (e) => {
                                resetHighlight(e);
                            },

                        }}
                    >

                        <Tooltip>
                            <p>{item.ID}</p>
                        </Tooltip>


                    </Polygon>
                    <Marker
                        key={`Mark:${item.ID}:${uuidv4()}`}
                        position={getCentroid([[item.C1a, item.C1o], [item.C2a, item.C2o], [item.C3a, item.C3o], [item.C4a, item.C4o]])}
                        icon={iconPin}
                        style={{zIndex: 1000}}
                        eventHandlers={{
                            click: () => {
                            },
                            popupopen: (e) => {
                                e.target.setIcon(iconPinRed);
                                setPopupActive(true);
                            },
                            popupclose: (e) => {
                                e.target.setIcon(iconPin);
                                setPopupActive(false);
                            },
                        }}

                    >
                        <Tooltip>
                            <p>{item.ID}</p>
                        </Tooltip>
                        <Popup
                            className="request-popup"
                            autoPanPaddingTopLeft={[50, 15]}
                            autoPanPaddingBottomRight={[10, 0]}
                            keepInView={true}
                            closeOnEscapeKey={true}
                            offset={[0, 10]}
                        >
                            <PopupContent item={item}/>
                        </Popup>
                    </Marker>
                </>
            ))
        ;

        setMarkers(result);
    }
}
