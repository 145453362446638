export const search = (options, state) => {
    const resultIDSearch = searchIDImp(options, state.inputValue);
    const resultGeneralSearch = searchImpMultiple(options, state.inputValue)
    return resultIDSearch.length !== 0 ? resultIDSearch : resultGeneralSearch;
}

const searchImp = (jsonArray, searchTerm) => {
    let results = [];

    // Use a regular expression to check if the search term matches any part of the JSON object
    let regex = new RegExp(searchTerm, "i");

    // Loop through the array of JSON objects
    for (let i = 0; i < jsonArray.length; i++) {
        let jsonObject = jsonArray[i];

        // Check if the JSON object contains the search term
        if (regex.test(JSON.stringify(jsonObject))) {
            results.push(jsonObject);
        }
    }

    return results;
}

const getKeyFromJsonObjectCaseInsensitive = (object, key) => {
    const asLowercase = key.toLowerCase();
    return Object.keys(object).find(k => k.toLowerCase() === asLowercase);
}

const searchImpMultiple = (jsonArray, searchTermPipeSeparated) => {
    let results = [];

    // Split the search term into an array of separate terms
    let searchTerms = searchTermPipeSeparated.split("|");

    // Loop through the array of JSON objects
    for (let i = 0; i < jsonArray.length; i++) {
        let jsonObject = jsonArray[i];

        // Flag to keep track of whether all search terms have been found
        let foundAllTerms = true;

        // Loop through each search term
        for (let j = 0; j < searchTerms.length; j++) {
            let term = searchTerms[j];

            // Extract the key and value from the search term
            let [key, value] = term.split(":");
            key = getKeyFromJsonObjectCaseInsensitive(jsonObject, key.trim());
            if (key === undefined) {
                foundAllTerms = false;
                break;
            } else {

                if (value === undefined) {
                    foundAllTerms = false;
                    break;
                } else {
                    value = value.trim();

                    // Check if the value contains square brackets (indicating a range)
                    if (value.startsWith("[") && value.endsWith("]")) {
                        // Extract the range from the brackets
                        value = value.substring(1, value.length - 1);

                        // Split the value into a range using the dash as a delimiter
                        let [lower, upper] = value.split(";");
                        lower = parseFloat(lower);
                        upper = parseFloat(upper);

                        // Check if the value of the key in the JSON object falls within the range
                        if (jsonObject[key] < lower || jsonObject[key] > upper) {
                            foundAllTerms = false;
                            break;
                        }
                    }
                    // Use regex to check if the value is a wildcard or not
                    else if (!new RegExp("^" + value.replace(/\[/g, '\\[').replace(/\]/g, '\\]')).test(jsonObject[key])) {
                        foundAllTerms = false;
                        break;
                    }
                }
            }
        }

        // If all search terms were found, add the JSON object to the results array
        if (foundAllTerms) {
            results.push(jsonObject);
        }
    }
    return results;
}

const searchIDImp = (jsonArray, searchTerm) => {
    let regex = new RegExp(searchTerm.replace(/\*/g, '.*').replace(/\[/g, '\\[').replace(/\]/g, '\\]').replace(/-/g, '\\-'), 'i');
    let results = [];
    for (let i = 0; i < jsonArray.length; i++) {
        if (regex.test(jsonArray[i].ID)) {
            results.push(jsonArray[i]);
        }
    }
    return results;
}