import React, {useCallback, useEffect, useState} from "react";
import {LatLng} from "leaflet";
import {LayersControl, MapContainer, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {Alert, AlertTitle} from "@mui/material";
import 'react-photo-view/dist/react-photo-view.css';
import 'leaflet.fullscreen/Control.FullScreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import AppBar from "../../components/appbar/AppBar";
import Footer from "../../components/footer/Footer";
import LoadingAnimation from "../../components/LoadingAnimation";
import {generateClusters} from "./GenerateClusters";
import {GenerateMarkers as generateMarkers} from "./GenerateMarkers";
import {fetchClusters, fetchMarkers, fetchRegions} from "../../components/services/backend";
import {baseLayers} from "../../components/configs/maps";
import JumpToLocationForm from "./JumpToLocationForm";
import ZoomLevel from "./ZoomLevel";
import AppBarSkeleton from "../../components/appbar/AppBarSkeleton";

const Map = () => {
    const mapInitData = {
        lat: -10,
        lng: 180,
        zoom: 2,
        maxZoom: 14,
        minZoom: 2,
        zoomDelta: 0.5,
        wheelPxPerZoomLevel: 50,
        maxBoundsViscosity: 0.5,
        attributionControl: false,
        style: {
            height: "100%",
            minHeight: "100%"
        }
    };

    const [map, setMap] = useState(null);
    // Used for rendering either clusters or markers
    const [markers, setMarkers] = useState(null);
    // Values set from API call
    const [dataLocation, setDataLocation] = useState(null);
    const [dataClusters100, setDataClusters100] = useState(null);
    const [dataClusters500, setDataClusters500] = useState(null);
    const [dataClusters1000, setDataClusters1000] = useState(null);
    const [dataRegions, setDataRegions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [popupActive, setPopupActive] = useState(false);
    const [coords, setCoords] = useState(null);
    const [inputCoords, setInputCoords] = useState({lat: '', lng: ''});
    const [zoomLevel, setZoomLevel] = useState(mapInitData.zoom);


    const handleJumpToCoords = useCallback(() => {
        if (map && inputCoords.lat && inputCoords.lng) {
            map.flyTo([parseFloat(inputCoords.lat), parseFloat(inputCoords.lng)], 8, {duration: 1});
        }
    }, [map, inputCoords.lat, inputCoords.lng]);

    const handleMapReady = useCallback((mapInstance) => {
        setMap(mapInstance);
    }, []);

    const {BaseLayer} = LayersControl;
    const ZoomComponent = ({onMapReady}) => {
        const map = useMap();

        useEffect(() => {
            onMapReady(map);
        }, [map, onMapReady]);

        const mapEvents = useMapEvents({
            click: (e) => {
                setCoords({lat: e.latlng.lat, lng: e.latlng.lng});
                generateMarkersBasedOnZoomLevel(mapEvents);
            },
            zoomend: () => {
                setZoomLevel(mapEvents.getZoom());
                generateMarkersBasedOnZoomLevel(mapEvents);
            },
            dragend: () => {
                if (!popupActive) {
                    generateMarkersBasedOnZoomLevel(mapEvents);
                }
            },
        });
        return null;
    }


    const generateClusters100 = generateClusters(dataClusters100, map, setMarkers, 4, 3);

    const generateMarkersBasedOnZoomLevel = useCallback((mapEvents) => {
        const generateMarker = generateMarkers(dataLocation, map, setPopupActive, setMarkers);
        const generateClusters500 = generateClusters(dataClusters500, map, setMarkers, 5, 1);
        const generateClusters1000 = generateClusters(dataClusters1000, map, setMarkers, 6, 0.5);

        if (mapEvents.getZoom() > 5) {
            generateMarker(mapEvents.getBounds());
        } else {
            switch (mapEvents.getZoom()) {
                case 0:
                case 1:
                case 2:
                case 3:
                    generateClusters100(mapEvents.getZoom());
                    break;
                case 4:
                    generateClusters500(mapEvents.getZoom());
                    break;
                case 5:
                    generateClusters1000(mapEvents.getZoom());
                    break;
                default:
                    break;
            }
        }
    }, [dataLocation, generateClusters100, dataClusters500, dataClusters1000, map, setPopupActive, setMarkers]);


    useEffect(() => {
        const FetchMarkers = fetchMarkers(setLoading, setDataLocation, setError);
        const FetchClusters100 = fetchClusters(setDataClusters100, 100, setLoading, setError);
        const FetchClusters500 = fetchClusters(setDataClusters500, 500, setLoading, setError);
        const FetchClusters1000 = fetchClusters(setDataClusters1000, 1000, setLoading, setError);
        const FetchRegions = fetchRegions(setLoading, setDataRegions, setError);

        FetchMarkers();
        FetchClusters100();
        FetchClusters500();
        FetchClusters1000();
        FetchRegions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        generateClusters100(mapInitData.zoom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataClusters100]);

    useEffect(() => {
        const element = document.getElementsByClassName('leaflet-control-attribution')[0];
        if (element) {
            element.style.fontSize = '0px';
        }
    }, []);


    return (
        <>
            <div className="header">
                {
                    dataLocation && dataRegions && map ?
                        <AppBar mapData={dataLocation} regionsData={dataRegions} mapInstance={map}/>
                        :
                        <AppBarSkeleton/>
                }
            </div>
            {loading && <><LoadingAnimation/></>}
            {error && <>
                {
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Error</AlertTitle>
                        There was an error loading the data. Please try again later: <strong>{error}</strong>
                    </Alert>
                }
            </>
            }
            {markers &&
                <div className={"map"} style={{}}>
                    <MapContainer
                        preferCanvas={false}
                        fullscreenControl={true}
                        //ref={mapRef}
                        center={new LatLng(mapInitData.lat, mapInitData.lng)}
                        zoom={mapInitData.zoom}
                        maxZoom={mapInitData.maxZoom}
                        minZoom={mapInitData.minZoom}
                        zoomDelta={mapInitData.zoomDelta}
                        wheelPxPerZoomLevel={mapInitData.wheelPxPerZoomLevel}
                        maxBoundsViscosity={mapInitData.maxBoundsViscosity}
                        style={mapInitData.style}
                        attributionControlPrefix={"<a href='https://www.leafletjs.com/'>Leaflet</a>"}
                    >
                        <LayersControl position="topright">
                            {baseLayers.map((baseLayer, index) => (
                                <BaseLayer
                                    key={index}
                                    checked={baseLayer.checked}
                                    name={baseLayer.name}
                                >
                                    <TileLayer
                                        url={baseLayer.tileLayer.url}
                                        attribution={baseLayer.tileLayer.attribution}
                                        maxNativeZoom={baseLayer.tileLayer.maxNativeZoom}
                                        tms={baseLayer.tileLayer.tms}
                                        updateWhenIdle={baseLayer.tileLayer.updateWhenIdle}
                                    />
                                </BaseLayer>
                            ))}
                        </LayersControl>
                        <>
                            {markers}
                        </>
                        <ZoomComponent onMapReady={handleMapReady}/>
                    </MapContainer>
                    <ZoomLevel zoomLevel={zoomLevel}/>
                    <JumpToLocationForm onSubmit={(e) => {
                        e.preventDefault(); // prevent form from reloading the page
                        handleJumpToCoords();
                    }} inputCoords={inputCoords} setInputCoords={setInputCoords}
                                        onChange={e => setInputCoords(prev => ({...prev, lat: e.target.value}))}
                                        onChange1={e => setInputCoords(prev => ({...prev, lng: e.target.value}))}
                                        coords={coords}
                    />
                </div>
            }
            <div className={"footer"}>
                <Footer/>
            </div>
        </>
    );
}

export default Map;
