import {Menu, MenuItem} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {QuestionMark, ShoppingCart} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const MobileMenu = (setMobileMoreAnchorEl, mobileMoreAnchorEl, isMobileMenuOpen, handleClickOpenDialog) => {
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const mobileMenuId = "primary-search-account-menu-mobile";

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton
                    size="medium"
                    color="inherit"
                    aria-label={"buy CaSSIS Book"}
                    target={"_blank"}
                    href={
                        "https://weberverlag.ch/products/nicolas-thomas-und-das-cassis-team-bilder-vom-mars"
                    }
                >
                    <ShoppingCart/>
                    <Typography variant="body1">Buy the CaSSIS Book</Typography>
                </IconButton>
            </MenuItem>
            <MenuItem>
                <IconButton size="large" color="inherit" aria-label={"show help"}
                            onClick={() => handleClickOpenDialog()}>
                    <QuestionMark aria-label={"Help"}/>
                    <Typography variant="body1">Help & Usage policy</Typography>
                </IconButton>
            </MenuItem>
        </Menu>
    );
    return {handleMobileMenuOpen, mobileMenuId, renderMobileMenu};
}