import {CircleMarker, Tooltip} from "react-leaflet";
import {LatLng} from "leaflet";
import React from "react";


export function generateClusters(dataClusters, map, setMarkers, flyToZoomLevel, zoomFactor = 1) {

    return (zoomLevel) => {
        const result = dataClusters?.map((cluster) => {
            let markerRadius = cluster.datapoints / zoomLevel / zoomFactor;
            return (
                <CircleMarker
                    key={cluster.centroid_id}
                    position={[cluster.centroid_lng, cluster.centroid_lat]}
                    color={"whitesmoke"}
                    center={new LatLng(cluster.centroid_lng, cluster.centroid_lat)}
                    radius={markerRadius}
                    onClick={() => {}}
                    eventHandlers={{
                        click: () => {
                            if (map) {
                                map.flyTo([cluster.centroid_lng, cluster.centroid_lat], flyToZoomLevel);
                            }
                        },
                    }}
                >
                    <Tooltip
                        permanent={false}
                        direction={"top"}
                        offset={[0, 0]}
                        opacity={0.8}
                        style={{
                            background: "transparent",
                        }}
                    >
                        {cluster.datapoints}{" "}
                        {cluster.datapoints > 1 ? "observations" : "cluster"}
                    </Tooltip>
                </CircleMarker>
            );
        });
        setMarkers(result);
    };
}
