import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

const  LoadingAnimation = () => {

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <CircularProgress/>
        </Backdrop>
    )
};

export default LoadingAnimation;