import * as React from "react";
import {BootstrapDialog, BootstrapDialogTitle, Transition} from "./subcomponents/helperComponents";
import {DialogActions, DialogContent, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export function CustomDialog({handleCloseDialog, openDialog}) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <BootstrapDialog
                onClose={handleCloseDialog}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                fullScreen
                TransitionComponent={Transition}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                    How to use the Search
                </BootstrapDialogTitle>
                <DialogContent dividers style={{height: 200}}>
                    <Typography component={"ul"} style={{paddingLeft: 10}}>
                        <Typography component={"li"} gutterBottom>
                            Start searching by typing in the search bar. You can search by image ID,
                            Latitude,
                            Longitude, Number of filters, the used filters, phase angle, Image execution
                            time, Phase
                            Counter, Incident Counter and Emission Counter.
                        </Typography>
                        <Typography component={"li"} gutterBottom>
                            To search for an image ID, just enter the ID like this: <span
                            style={{fontStyle: "", fontFamily: "monospace"}}>MY34_001620_157_0 </span>
                            or like this: id: <span
                            style={{fontStyle: "", fontFamily: "monospace"}}>MY34_001620_157_0</span>
                        </Typography>
                        <Typography component={"li"} gutterBottom>
                            To search for multiple values, separate them with a pipe (|). For example, if
                            you want
                            to
                            search for images with Latitude's of 186, PhaseAngle's of 73 and number of
                            filters 1
                            type:
                            <span style={{fontStyle: "", fontFamily: "monospace"}}> Longitude: 186|PhaseAngle: 73|NrFilters: 1</span> in
                            the search bar.
                        </Typography>
                        <Typography component={"li"} gutterBottom>
                            To search for a range of values, you need to place the lower and upper bound
                            between
                            square brackets [ ] seperated by a ";":
                            <span style={{fontStyle: "", fontFamily: "monospace"}}> latitude: [20;25]|longitude: [25;30]|emissionctr:12 </span>
                        </Typography>
                    </Typography>
                </DialogContent>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                    Information Sources for CaSSIS Mission and Instrument Data
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        This Website uses <Link
                        href="https://leafletjs.com/"
                        target={"_blank"}>Leaflet</Link>. We are not responsible for what is being displayed on that website.<br/>
                        The Mars Basemaps are used from <Link
                        href="https://www.openplanetary.org/opm/basemaps"
                        target={"_blank"}>OpenPlanetaryMap</Link>.<br/>
                        Basic information about CaSSIS can be found at <Link
                        href="https://www.cassis.unibe.ch/"
                        target={"_blank"}>www.cassis.unibe.ch</Link> which
                        includes a news site.<br/>
                        A brief description about the mission and the instrument can be found in <Link
                        href="https://www.cassis.unibe.ch/instrument/"
                        target="_blank">www.cassis.unibe.ch/instrument</Link>
                    </Typography>
                    <Typography gutterBottom>
                        A full instrument description is provided in Thomas et al. (2017) (<Link
                        href="https://link.springer.com/article/10.1007/s11214-017-0421-1"
                        target="_blank">link.springer.com/article/10.1007/s11214-017-0421-1</Link>).
                        The European Space Agency web page for the spacecraft can be found at <Link
                        href="https://www.esa.int/Science_Exploration/Human_and_Robotic_Exploration/Exploration/ExoMars/Trace_Gas_Orbiter_instruments"
                        target="_blank">www.esa.int/Science_Exploration/Human_and_Robotic_Exploration/Exploration/ExoMars/Trace_Gas_Orbiter_instruments</Link> The
                        raw data can also be found at the Planetary Science Archive of the European Space
                        Agency
                        at <Link hfref="https://archives.esac.esa.int/psa/#!Table%20View/CaSSIS=instrument"
                                 target="_blank">https://archives.esac.esa.int/psa/#!Table%20View/CaSSIS=instrument</Link>
                    </Typography>
                    <Typography gutterBottom>
                        The PSA forms the formal repository for CaSSIS data. The raw data here are identical
                        to
                        the
                        data
                        submitted to PSA for publication. The geometrically rectified and corrected data on
                        this
                        site
                        are published in advance of delivery to the PSA and sometimes prior to review. This
                        may
                        mean
                        that header information can change if (for example) something is established to be
                        incorrect
                        (e.g. not compatible with the PDS V4 standard). However, we do our best to ensure
                        that
                        this
                        is
                        as close to final as our knowledge allows.
                    </Typography>
                    <Typography gutterBottom>
                        Our data complements that of HiRISE and you are referred to <Link
                        href="https://www.uahirise.org/catalog/"
                        target="_blank">www.uahirise.org/catalog</Link> for
                        HiRISE data.
                    </Typography>
                </DialogContent>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
                    Considerations for Interpreting CaSSIS Data: SNR, Illumination Angle, and Straylight
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Our data have two names – the MYxx form you see here applies to the final products.
                        The
                        other
                        name (which includes the framelet acquisition time) is used for the raw at lower
                        levels
                        of
                        processing and is needed to keep track of framelets in the telemetry conversion
                        process.
                        For
                        geometrically corrected data, you do not need this lower level – the MYxx format
                        should
                        be
                        sufficient.
                    </Typography>
                    <Typography gutterBottom>
                        Our data can be taken at many different local solar times including close to or even
                        beyond
                        the
                        terminators. In some cases this can result in very low signal to noise. ALL data are
                        available
                        on the web site so you will see these images if you scroll through the full data
                        set.
                        CaSSIS
                        is
                        a high SNR instrument and so some images up to 87 degrees illumination angle can
                        still
                        be
                        useful. However, this strongly depends on the dust load. High optical depths result
                        in
                        poor
                        images. In particular, low signal to noise can result in poorer colour alignment and
                        evidence of
                        colour fringing. We have put a lot of effort into trying to optimise/improve
                        registration.
                        However, there are limits. Consequently, dusty or poorly illuminated images should
                        be
                        checked
                        visually.
                    </Typography>
                    <Typography gutterBottom>
                        Images were taken during dust storms. These data often do not show the surface at
                        all
                        (optical
                        depth >> 1). These are useless for surface studies but may have some interest for
                        atmospheric
                        physicists.
                    </Typography>
                    <Typography gutterBottom>
                        Some images (especially with poor SNR) might show some linear structure. This is a
                        consequence
                        of residual straylight in the system (probably a spurious reflection from a field
                        stop).
                        Unfortunately this straylight is variable and depends upon the phase of Mars at the
                        position
                        of
                        the spacecraft when imaging. Our automated tools are close to the limit of what can
                        be
                        achieved.
                        Individual analysis may provide slightly cleaner data but the errors are small and
                        only
                        revealed
                        in low SNR data. If you really, really need exactly that image, please contact us.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCloseDialog}>
                        DONE
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}

