import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";
import * as React from "react";


const Copyright = () => {
    return (
        <Typography variant="body2" color="white">
            {'Copyright © '}
            <Link color="inherit" href="https://www.unibe.ch/" target="_blank">
                University of Bern
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;