import * as React from 'react';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import PropTypes from "prop-types";
import {MobileMenu} from "./MobileMenu";
import {BootstrapDialogTitle} from "./subcomponents/helperComponents";
import {CustomDialog} from "./CustomDialog";
import {ToolBar} from "./ToolBar";


ToolBar.propTypes = {
    mapData: PropTypes.any,
    regionsData: PropTypes.any,
    mapInstance: PropTypes.any,
    onClick: PropTypes.func,
    ariaControls: PropTypes.string,
    onClick1: PropTypes.func
};
const PrimarySearchAppBar = (props) => {

    const [openDialog, SetOpenDialog] = useState(false);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleOpenDialog = () => {
        SetOpenDialog(true);
    };


    const {
        handleMobileMenuOpen,
        mobileMenuId,
        renderMobileMenu
    } = MobileMenu(setMobileMoreAnchorEl, mobileMoreAnchorEl, isMobileMenuOpen, handleOpenDialog);

    const handleCloseDialog = () => {
        SetOpenDialog(false);

    };

    return (
        <Box sx={{flexGrow: 1}}>
            <CustomDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog}/>
            <AppBar position="static">
                <ToolBar mapData={props.mapData} regionsData={props.regionsData} mapInstance={props.mapInstance}
                         onClick={() => handleOpenDialog()} ariaControls={mobileMenuId}
                         onClick1={handleMobileMenuOpen}/>
            </AppBar>
            {renderMobileMenu}
        </Box>
    );
}


BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default PrimarySearchAppBar;