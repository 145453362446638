export const baseLayers = [
    {
        name: "OPM Mars Basemap v0.2",
        checked: true,
        tileLayer: {
            url: "https://cartocdn-gusc.global.ssl.fastly.net/opmbuilder/api/v1/map/named/opm-mars-basemap-v0-2/all/{z}/{x}/{y}.png",
            attribution: "&copy; <a href='https://www.openplanetary.org/opm-basemaps/opm-mars-basemap-v0-2'>OPM Mars Basemap v0.2</a>",
            maxNativeZoom: 12,
            updateWhenIdle: false
        }
    },
    {
        name: "Mars Shaded Colour MOLA Elevation",
        checked: false,
        tileLayer: {
            url: "http://s3-eu-west-1.amazonaws.com/whereonmars.cartodb.net/mola-color/{z}/{x}/{y}.png",
            attribution: "&copy; <a href='https://www.openplanetary.org/opm-basemaps/shaded-colour-mola-elevation'>Mars Shaded Colour MOLA Elevation</a>",
            maxNativeZoom: 6,
            tms: true,
            updateWhenIdle: false
        }
    },
    {
        name: "Mars Shaded Grayscale MOLA Elevation",
        checked: false,
        tileLayer: {
            url: "http://s3-eu-west-1.amazonaws.com/whereonmars.cartodb.net/mola-gray/{z}/{x}/{y}.png",
            attribution: "&copy; <a href='https://www.openplanetary.org/opm-basemaps/shaded-grayscale-mola-elevation'>Mars Shaded Grayscale MOLA Elevation</a>",
            maxNativeZoom: 9,
            tms: true,
            updateWhenIdle: false
        }
    }
];
