import Toolbar from "@mui/material/Toolbar";
import {Link, Menu, MenuItem} from "@mui/material";
import Box from "@mui/material/Box";
import cassisLogo from "../ressources/cassis_logo.png";
import Searchbar from "../searchbar/Searchbar";
import IconButton from "@mui/material/IconButton";
import {QuestionMark, ShoppingCart} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import MoreIcon from "@mui/icons-material/MoreVert";
import * as React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {IncrementTotalDownloads} from "../services/backend";
import {storageBaseUrl} from "../../components/configs/urls";

export const ToolBar = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const downloadItem = (file) => {
        IncrementTotalDownloads(file, "shapefiles");
        const link = document.createElement('a');
        link.href = `${storageBaseUrl}/shapefiles/${file}`;
        link.setAttribute('download', "_" + file);
        document.body.appendChild(link);
        link.click();

    };

    return (
        <Toolbar>
            <Link href="/" style={{marginRight: 10}}>
                <Box
                    component="img"
                    sx={{
                        height: 45,
                    }}
                    alt="Your logo."
                    src={cassisLogo}
                    href="/"
                />
            </Link>
            <div style={{color: "whitesmoke"}}>
                <Searchbar mapData={props.mapData} regionsData={props.regionsData}
                           mapInstance={props.mapInstance}/>
            </div>
            <IconButton
                color="inherit"
                aria-controls="download-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <DownloadIcon/>
                <Typography variant="body1">
                    Downloads
                </Typography>
            </IconButton>
            <Menu
                id="download-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={(e) => downloadItem('CaSSIS_acquired_footprints_colour.tar')}><DownloadIcon/>CaSSIS_acquired_footprints_colour.tar</MenuItem>
                <MenuItem onClick={() => downloadItem('CaSSIS_acquired_footprints_coverage.tar')}><DownloadIcon/>CaSSIS_acquired_footprints_coverage.tar</MenuItem>
                <MenuItem onClick={() => downloadItem('CaSSIS_acquired_observations_log.csv')}><DownloadIcon/>CaSSIS_acquired_observations_log.csv</MenuItem>
            </Menu>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{display: {xs: "none", md: "flex"}}}>
                <IconButton
                    size="medium"
                    color="inherit"
                    aria-label={"buy CaSSIS Book"}
                    target={"_blank"}
                    href={"https://weberverlag.ch/products/nicolas-thomas-und-das-cassis-team-bilder-vom-mars"}
                >
                    <ShoppingCart/>
                    <Typography variant="body1">
                        Buy the CaSSIS Book
                    </Typography>
                </IconButton>
                <IconButton
                    size="large"
                    color="inherit"
                    aria-label={"show help"}
                    onClick={props.onClick}
                >
                    <QuestionMark aria-label={"Help"}/>
                    <Typography variant="body1">
                        Help & Usage policy
                    </Typography>
                </IconButton>
            </Box>
            <Box sx={{display: {xs: "flex", md: "none"}}}>
                <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={props.ariaControls}
                    aria-haspopup="true"
                    onClick={props.onClick1}
                    color="inherit"
                >
                    <MoreIcon/>
                </IconButton>
            </Box>
        </Toolbar>
    );
}
