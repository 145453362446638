import React from 'react';
import Skeleton from "@mui/material/Skeleton";


const AppBarSkeleton = () => {
    return (
        <div style={{display: 'flex', alignItems: 'center', padding: '0 16px', height: 64}}>
            <Skeleton variant="text" width={100} height={50}/>
            <Skeleton variant="text" width={400} height={50} style={{marginLeft: 16}}/>
            <Skeleton variant="text" width={100} height={50} style={{marginLeft: 16}}/>
            <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'right'}}>
                <Skeleton variant="text" width={100} height={50} style={{marginLeft: 16}}/>
                <Skeleton variant="text" width={100} height={50} style={{marginLeft: 16}}/>
            </div>
        </div>
    )
}

export default AppBarSkeleton;
