import React from "react";

function ZoomLevel(props: { zoomLevel: number }) {
    return <div style={{
        position: "absolute",
        bottom: "52px",
        left: "5px",
        background: "whitesmoke",
        opacity: 0.8,
        padding: "2px",
        borderRadius: "3px",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
    }}>
        Current Zoom Level: {props.zoomLevel}
    </div>;
}

export default ZoomLevel;
