import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popper from '@mui/material/Popper';
import {styled, useTheme} from '@mui/material/styles';
import {VariableSizeList} from 'react-window';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import {InputAdornment} from "@mui/material";
import {useEffect} from "react";
import {updateLatLong} from "../helper";
import {search} from "./search";

//
// Most of the code comes from https://mui.com/components/autocomplete/#virtualization
//

let merged_data;
const LISTBOX_PADDING = 8; // px
const renderRow = (props) => {
    const {data, index, style} = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
        fontSize: 14,
        display: 'block',
        borderBottom: '1px solid #ccc',
        marginBottom: 5,
    };
    const detailStyle = {
        fontSize: 12,
        display: "block",
    }


    const currentItem = merged_data.filter((item) => item.ID === dataSet[1])[0];
    return (
        <>
            <Typography component="li" {...dataSet[0]} style={inlineStyle}>
                <strong>{dataSet[1]}</strong>
                <div style={detailStyle}>
                    <strong>Latitude: </strong>{currentItem.Latitude}
                    <strong> Longitude: </strong>{currentItem.Longitude}
                </div>
                <div style={detailStyle}>
                    <strong>NrFilters: </strong>{currentItem.NrFilters}
                    <strong> FiltersUsed: </strong>{currentItem.FiltersUsed}
                </div>
                <div style={detailStyle}>
                    <strong>PhaseAngle: </strong>{currentItem.PhaseAngle}
                    <strong> ImgExecTime: </strong>{currentItem.ImgExecTime}
                </div>
                <div style={detailStyle}>
                    <strong>PhaseCtr: </strong>{currentItem.PhaseCtr}
                    <strong> IncidenceCtr: </strong>{currentItem.IncidenceCtr}
                    <strong> EmissionCtr: </strong>{currentItem.EmissionCtr}
                </div>
            </Typography>
        </>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const {children, ...other} = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = () => {
        return itemSize + 70;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={10}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};


const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});


const Searchbar = (props) => {
    //console.log(props);

    const [mapData, setMapData] = React.useState([props.mapData]);
    const regionsData = props.regionsData;
    merged_data = mapData.concat(regionsData);

    const map = props.mapInstance;

    const OPTIONS = merged_data;

    //const [selectedOption, setSelectedOption] = React.useState(null);

    useEffect(() => {
        setMapData(updateLatLong(props.mapData))
    }, [props.mapData]);


    const getSingleItem = (v) => {
        if (v === null) {
            //setSelectedOption(null);
            return null;
        }

        const res = merged_data.filter((item) => item.ID === v.ID)[0];
        map.flyTo([res.Latitude, res.Longitude], 8);
        map.openPopup("Click for more details.", [res.Latitude, res.Longitude], {
            width: 200,
            autoPan: false
        });
        return res;
    }

    const StyledAutocomplete = styled(Autocomplete)({
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
        },
        "& .MuiAutocomplete-inputRoot": {
            backgroundColor: "whitesmoke",
            padding: "5px 8px",
        },
    });


    return (
        <>
            <StyledAutocomplete
                id="virtualize"
                sx={{width: 500,}}
                disableListWrap
                PopperComponent={StyledPopper}
                ListboxComponent={ListboxComponent}
                options={OPTIONS}
                bgcolor="background.paper"
                renderInput={
                    (params) => <TextField {...params} label=""
                                           placeholder="Search..."
                                           InputProps={{
                                               ...params.InputProps,
                                               startAdornment: (
                                                   <InputAdornment
                                                       position="end"> <SearchIcon sx={{color: "black"}}/>
                                                   </InputAdornment>)
                                           }}/>
                }
                renderOption={(props, option) => [props, option.ID]}
                getOptionLabel={option => option.ID}
                // TODO: Post React 18 update - validate this conversion, look like a hidden bug
                //renderGroup={(params) => params}
                handleHomeEndKeys={true}
                //value={selectedOption}
                onChange={(e, v) => getSingleItem(v)}
                filterOptions={search}
            />
        </>
    );
}

export default Searchbar;
