import {PhotoProvider, PhotoView} from "react-photo-view";
import React, {useState} from "react";
import DownloadableItemsView from "./DownloadableItemsView";
import TabPanel from "./TabPanel";
import {Tab, Tabs} from "@mui/material";
import {storageBaseUrl} from "../../components/configs/urls";
import Box from "@mui/material/Box";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';

function PopupContent({item}) {
    const [CurrentTabValue, setCurrentTabValue] = useState(0);
    const [copied, setCopied] = useState(false);


    const handleTabChange = (event, newValue) => {
        setCurrentTabValue(newValue);
    };


    return (
        <Box sx={{flexGrow: 10, backgroundColor: 'whitesmoke',}}>
            <Tabs
                value={CurrentTabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab label="Image Details"/>
                <Tab label="Downloads"/>
            </Tabs>
            <TabPanel value={CurrentTabValue} index={0}>
                <PhotoProvider
                    speed={() => 700}
                    easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
                    maskOpacity={0.9}
                >
                    <PhotoView
                        src={storageBaseUrl + "/" + item.MTP.replace(/^M0/, "M") + "/" + item.ID + "/preview_big.jpg"}
                        width={200}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <img
                                src={storageBaseUrl + "/" + item.MTP.replace(/^M0/, "M") + "/" + item.ID + "/preview.jpg"}
                                style={{ maxHeight: "200px", maxWidth: "100%", marginBottom: 20 }}
                                alt="no img"
                            />
                        </div>
                    </PhotoView>
                </PhotoProvider>

                <p style={{textAlign: "center", borderStyle: "solid", fontWeight: "bold"}}>
                  <span style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {item.ID}
                      <Tooltip title={"Copy image ID"}>
                      <ContentCopyIcon
                          className={copied ? 'copied' : ''}
                          style={{cursor: "pointer", position: 'absolute', right: '20px', marginTop: '1px'}}
                          onClick={() => {
                              navigator.clipboard.writeText(item.ID);
                              setCopied(true);
                              setTimeout(() => setCopied(false), 1000); // Reset after 1 second
                          }}
                      />

                    </Tooltip>
                  </span>
                </p>
                <p>
                    <span>Latitude:</span>
                    <span className={"detail-value"}>{item.Latitude.toFixed(5)}</span>
                </p>
                <p>
                    <span>Longitude:</span>
                    <span className={"detail-value"}>{item.Longitude.toFixed(5)}</span>
                </p>
                <p>
                    <span>PhaseAngle:</span>
                    <span className={"detail-value"}>{item.PhaseAngle}</span>
                </p>
                <p>
                    <span>Number of filters:</span>
                    <span className={"detail-value"}>{item.NrFilters}</span>
                </p>
                <p>
                    <span>Used filters:</span>
                    <span className={"detail-value"}>{item.FiltersUsed}</span>
                </p>
                <p>
                    <span>Width:</span>
                    <span className={"detail-value"}>{item.Width}</span>
                </p>
                <p>
                    <span>PhaseCtr:</span>
                    <span className={"detail-value"}>{item.PhaseCtr}</span>
                </p>
                <p>
                    <span>IncidenceCtr:</span>
                    <span className={"detail-value"}>{item.IncidenceCtr}</span>
                </p>
                <p>
                    <span>EmissionCtr:</span>
                    <span className={"detail-value"}>{item.EmissionCtr}</span>
                </p>
                <p>
                    <span>ImgExecTime:</span>
                    <span className={"detail-value"}>{item.ImgExecTime}</span>
                </p>
            </TabPanel>
            <TabPanel value={CurrentTabValue} index={1}>
                <DownloadableItemsView imageItem={item}/>
            </TabPanel>
        </Box>
    );
}

export default PopupContent;
