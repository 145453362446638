import React, {useEffect} from "react";

function JumpToLocationForm({
                                onSubmit,
                                inputCoords,
                                setInputCoords,  // Adding setInputCoords prop
                                onChange,
                                onChange1,
                                coords,  // Adding coords prop
                            }: {
    onSubmit: ((e: React.FormEvent) => void) | any,
    inputCoords: { lng: string; lat: string },
    setInputCoords: React.Dispatch<React.SetStateAction<{ lng: string; lat: string }>>, // Setting up the type for setInputCoords
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onChange1: (e: React.ChangeEvent<HTMLInputElement>) => void,
    coords: { lat: string, lng: string } | null,  // Setting up the type for coords
}) {
    useEffect(() => {
        if (coords) {
            setInputCoords({
                lat: parseFloat(coords.lat).toFixed(6),
                lng: parseFloat(coords.lng).toFixed(6)
            }); // Set the input fields when coords are updated
        }
    }, [coords, setInputCoords]);

    return (
        <form
            onSubmit={onSubmit}
            style={{
                position: "absolute",
                bottom: "3px",
                left: "5px",
                background: "whitesmoke",
                opacity: 0.8,
                padding: "2px",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                borderRadius: '3px',
                fontSize: "15px",
            }}
        >
            {(inputCoords.lat !== "" && inputCoords.lng !== "") ?
                `Latitude: ${inputCoords.lat}, Longitude: ${inputCoords.lng}` :
                "Enter the coordinates or click on the map you want to jump to"}
            <div style={{display: "flex", marginTop: "5px"}}>
                <input
                    type="text"
                    placeholder="Lat"
                    value={inputCoords.lat}
                    onChange={onChange}
                    style={{marginRight: "5px"}}
                />
                <input
                    type="text"
                    placeholder="Lng"
                    value={inputCoords.lng}
                    onChange={onChange1}
                    style={{marginRight: "5px"}}
                />
                <button type="submit">Go</button>
            </div>
        </form>
    );
}

export default JumpToLocationForm;
