import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Link} from "@mui/material";
import unibeLogo from "../ressources/unibe_logo_footer.png";
import * as React from "react";
import Copyright from "./copyright";

const Footer = () => {
    return (
        <>
            <Box
                display="grid"
                gridTemplateColumns="1fr 2fr 1fr"
                gridAutoRows="auto"
                rowGap={1}
                alignItems="center"
                color={"white"}
            >
                <Box gridColumn="2">

                    <Box sx={{
                        justifyContent: "center",
                        display: "flex",
                        p: 1.5,
                        alignItems: "center",
                        flexDirection: 'column',
                    }} component="footer" variant="outlined"
                    >

                        <Typography variant="body1">
                            Physics Institute
                            University of Bern
                            Sidlerstrasse 5
                            CH-3012 Bern
                        </Typography>
                        <Copyright/>
                    </Box>
                </Box>
                <Box
                    gridColumn={{xs: "1 / -1", sm: "3"}}
                    gridRow={{xs: "2", sm: "1"}}
                    justifySelf={{xs: "center", sm: "end"}}
                >
                    <Link href="https://www.cassis.unibe.ch/" target="_blank">
                        <Box
                            component="img"
                            sx={{
                                height: 50,
                                flexDirection: 'row',
                            }}
                            alt="Logo of University of Bern"
                            src={unibeLogo}
                        />
                    </Link>
                </Box>
            </Box>
        </>
    );
}

export default Footer;