export const downloadableItems = [
    {
        type: "Raw",
        files: ["level0.tar", "level1C.tar", "level3C.tar"]
    },
    {
        type: "GIS ready",
        files: ["cubes.tar", "extras.tar"]
    },
    {
        type: "Map",
        files: ["map.tar"]
    },
];