// Function to calculate the centroid (average position) of an array of 2D points
export const getCentroid = (arr) => {
    return arr.reduce(function (x, y) {
        return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length]
    }, [0, 0])
}

// Function to update the latitude and longitude properties of an array of objects
export function updateLatLong(json) {
    // Loop through each object in the array
    json.forEach(function (obj) {
        // Create an array of coordinates from the object's properties
        const coordinates = [[obj.C1a, obj.C1o], [obj.C2a, obj.C2o], [obj.C3a, obj.C3o], [obj.C4a, obj.C4o]];
        // Calculate the centroid of the coordinates using the getCentroid function
        let centroid = getCentroid(coordinates);
        // Update the object's Latitude property with the first element of the centroid array
        obj.Latitude = centroid[0];
        // Update the object's Longitude property with the second element of the centroid array
        obj.Longitude = centroid[1];
    });
    // Return the updated array of objects
    return json;
};
