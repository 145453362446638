import L from 'leaflet';

const iconPin = new L.Icon({
    iconUrl: require('./ressources/pin_white.png'),
    iconRetinaUrl: require('./ressources/pin_white.png'),
    iconAnchor: null,
    popupAnchor: [0, -15],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: [4, 62],
    iconSize: new L.Point(20, 20),
});

export { iconPin };