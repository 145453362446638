import {Button, Stack} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import DownloadIcon from "@mui/icons-material/Download";
import React from "react";
import {storageBaseUrl} from "../../components/configs/urls";
import {IncrementTotalDownloads} from "../../components/services/backend";
import {downloadableItems} from "../../components/configs/downloadableItems";

const DownloadableItemsView = (props) => {

    const downloadItem = (file, ImageID, mtp) => {
        IncrementTotalDownloads(file, ImageID);
        const link = document.createElement('a');
        link.href = storageBaseUrl + "/" + props.imageItem.MTP.replace(/^M0/, "M") + "/" + props.imageItem.ID + "/" + file;
        link.setAttribute('download', ImageID + "_" + file);
        document.body.appendChild(link);
        link.click();

    };

    return downloadableItems.map((item) => {
        return (
            <>
                <h3>{item.type}</h3>
                <Stack direction={"column"} spacing={2} key={uuidv4()} sx={{pt: 1, pb: 1}}>
                    {item.files.map((file) => {
                        return (
                            <Button
                                variant="contained"
                                key={uuidv4()}
                                onClick={() => downloadItem(file, props.imageItem.ID, props.imageItem.MTP)}
                                type={"submit"}
                                startIcon={<DownloadIcon/>}
                            >
                                {file}
                            </Button>
                        )
                    })}
                </Stack>
                <br/>
            </>
        )
    });
};
export default DownloadableItemsView;
