import axios from "axios";
import {apiBaseurl} from "../configs/urls";

export const IncrementTotalDownloads = (downloadedItem, imgUnicID) => {
    axios.post(`${apiBaseurl}/statistics/downloads`, {
        imgUnicID: imgUnicID,
        downloadedItem: downloadedItem
    }).then(r => r);
};

export function fetchMarkers(setLoading, setDataLocation, setError) {
    return async () => {
        setLoading(true);
        try {
            const responseMarkers = await axios.get(
                `${apiBaseurl}/extras`
            );
            setDataLocation(responseMarkers.data.data);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
}

export function fetchClusters(setDataClusters, numberOfClusters, setLoading, setError) {
    return async () => {
        setLoading(true);
        try {
            const responseClusters = await axios.get(`${apiBaseurl}/clusters/${numberOfClusters}`);
            setDataClusters(responseClusters.data.clusters);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
}

export function fetchRegions(setLoading, setDataRegions, setError) {
    return async () => {

        setLoading(true);
        try {
            const responseRegions = await axios.get(
                `${apiBaseurl}/regions`
            );
            setDataRegions(responseRegions.data.data);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
}
